import React, { Component } from 'react';
import '../Carrousel.css';
import { images } from '../../constants/Portfolio';

class CustomGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewerIsOpen: false,
      currentImage: 0,
      photos: this.getPhotos(),
    };
  }

  openLightbox = (src) => {
    const burgerMenuToggle = document.getElementById('burger-menu-toggle-id');
    burgerMenuToggle.style.visibility = 'hidden';
    document.body.classList.add('lightbox-open'); // Add the class to prevent scrolling
    this.setState({ currentImage: src, viewerIsOpen: true });
  };
  
  closeLightbox = () => {
    const burgerMenuToggle = document.getElementById('burger-menu-toggle-id');
    burgerMenuToggle.style.visibility = 'visible';
    document.body.classList.remove('lightbox-open'); // Remove the class to enable scrolling
    this.setState({ currentImage: 0, viewerIsOpen: false });
  };

  getPhotos() {
    const result = [];

    function getId(className, id) {
      if (className && className.includes("first-pic")) {
        return id;
      }
      return null; // Return null if the className doesn't contain "first-pic"
    }
     
    Object.keys(images).forEach((key) => {
      const imagesArray = images[key];
      for (let i = imagesArray.length - 1; i >= 0; i--) {
        const image = {
          src: '',
          width: 0,
          height: 0,
          category: '',
          id: '',
        };

        if (imagesArray[i][1].includes('youtube-video')) {
          image.src = imagesArray[i][0];
          image.width = 560;
          image.height = 315;
          image.category = imagesArray[i].key;
        } else {
          image.src = 'gallery/' + key + '/thumbs/' + imagesArray[i][0];
          image.width = imagesArray[i][2];
          image.height = imagesArray[i][3];
          image.className = imagesArray[i][1];
          image.category = key;
          image.id = getId(imagesArray[i][1], key);
        }

        result.push(image);
      }
    });

    return result;
  }
  
  removeOverlay = (id) => {
    const overlay = document.getElementById(id);
    overlay.style.display = 'none';
  };
  
  contextMenu = (e) => {
    e.preventDefault();
    const year = new Date().getFullYear();
    alert(`This photo is Copyright © ${year}\nGabriel Patrício. All rights reserved`);
  };

  preventDragHandler = (e) => {
    e.preventDefault();
  };

  stopPropagation = (e) => {
    e.stopPropagation();
  };

  render() {
    const { viewerIsOpen, currentImage, photos } = this.state;

    // Group photos by category
    const groupedPhotos = {};
    photos.forEach((photo) => {
      if (!groupedPhotos[photo.category]) {
        groupedPhotos[photo.category] = [];
      }
      groupedPhotos[photo.category].push(photo);
    });

    return (
      <div className="containerMobile" id="containerMobile">
        <div className="contentMobile" onContextMenu={this.contextMenu} onDragStart={this.preventDragHandler}>
          <div>
            {/* Render each category in a separate container */}
            {Object.keys(groupedPhotos).map((category) => (
              <div key={category} className="categoryContainer" id={category}>
                <div className="albumOverlay" id={`overlay`+category} onClick={() => this.removeOverlay(`overlay`+category)}>
                  <div className="overlayContent">
                    <img id='albumOverlayIcon' src="/icons/eye.png" alt="Icon" className="overlayIcon" />
                    <h1 id='albumOverlayTitle'>{category}.</h1>
                  </div>
                </div>
                <div className="albumContainer">
                  {groupedPhotos[category].map((photo, index) => (
                    <img
                      key={index}
                      src={photo.src}
                      alt={`Category: ${category}`}
                      onClick={() => this.openLightbox(photo.src)}
                    />
                  ))}
                </div>
              </div>
            ))}
            {viewerIsOpen && (
              <div className="overlay" onClick={this.closeLightbox}>
                {/* Clicking on the overlay should also close the lightbox */}
                <div className="lightbox" onClick={this.stopPropagation}>
                  <img src={currentImage.replace('/thumbs/', '/')}/>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default CustomGallery;
