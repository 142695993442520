import React, { Component } from 'react';
import { Button, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { menuItems } from '../../constants/Portfolio';
import { updateMenus } from '../functions/Helpers';

class MainMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentMenuItemIndex: 0,
      visibleMenuItems: 3, // Number of initially visible menu items (changed to 3)
    };
  }

  componentDidMount() {
    // Update the currentMenuItemIndex when the component mounts or menuItems change
    this.setState({ currentMenuItemIndex: 0 });
  }

  scrollTo = (menuItem) => {
    const albumDiv = document.getElementById(menuItem);
    if (albumDiv) {
      albumDiv.scrollIntoView({ behavior: 'smooth' });
      updateMenus(menuItem, this.setCurrentMenuItemIndex);
      this.setState({ currentMenuItemIndex: menuItems.indexOf(menuItem) });
    }
  };

  handleDownArrowClick = () => {
    // Increase the number of visible menu items by 2
    this.setState((prevState) => ({
      visibleMenuItems: prevState.visibleMenuItems + 2,
    }));
  };

  render() {
    const { visibleMenuItems } = this.state;

    const menuButtons = menuItems.slice(0, visibleMenuItems).map((menuItem, index) => (
      <Link key={index}>
        <Button
          id={`menu-item-${index}`}
          onClick={() => this.scrollTo(menuItem)}
          variant="link"
          className="menu-button" // Add this class
          style={{
            color: '#dddd',
            textAlign: 'left',
            paddingLeft: 0,
          }}
        >
          {menuItem}
        </Button>
      </Link>
    ));

    const hasMoreMenuItems = menuItems.length > visibleMenuItems; // Check if there are more menu items to display

    return (
      <div className="sidebarmenu" style={{ display: `${this.props.display}` }}>
        <Nav className="flex-column">
          {menuButtons}
          {hasMoreMenuItems && (
            <Button
              id="next-menu-arrow-down"
              onClick={this.handleDownArrowClick}
              variant="link"
              style={{
                color: '#dddd',
                textAlign: 'left',
                paddingLeft: 0,
              }}
            >
              +{/* Change the down arrow to a plus sign */}
            </Button>
          )}
        </Nav>
      </div>
    );
  }
}

export default MainMenu;
