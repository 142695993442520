import { menuItems } from "../../constants/Portfolio";

export function updateMenus(id) {
    var index = menuItems.indexOf(id);
    
    // Remove the "active-menu-item" class from all menu buttons/links
    var allMenuItems = document.querySelectorAll('.menu-button');
    allMenuItems.forEach(item => {
        item.classList.remove('active-menu-item');
    });

    // Add the "active-menu-item" class to the current menu button/link
    var currentMenu = document.getElementById(`menu-item-${index}`);
    if (currentMenu != null) {
        currentMenu.classList.add('active-menu-item');
    }
}