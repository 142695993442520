import React, { Component } from 'react';

class HeroImage extends Component {
  render() {
    const { gifSrc, gifContainerStyle } = this.props;

    return (
      <div style={gifContainerStyle} id="gifcontainer">
        <img src={gifSrc} alt="GIF" style={{ width: "100%" }}/>
      </div>
    );
  }
}

export default HeroImage;
