import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

class PortfolioButton extends Component {
  render() {
    const { portfolioButtonStyle } = this.props;

    return (
      <Button style={portfolioButtonStyle} variant="link">PORTFOLIO</Button>
    );
  }
}

export default PortfolioButton;
