import React, { Component, useEffect } from 'react';
import Footer from "../shared/Footer";
import HeroImage from "../shared/HeroImage";
import PortfolioButton from "../shared/PortfolioButton";
import { Link } from 'react-router-dom';
import CustomGallery from "./CustomGallery";
import { menuItems } from '../../constants/Portfolio';

class Mobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showGallery: false,
      isBurgerMenuOpen: false,
    };
  }

  enterPortfolio() {
    this.setState({ showGallery: true });
    const burgerToggle = document.getElementById("burger-menu-toggle-id");
    burgerToggle.style.visibility = 'visible';
  }

  toggleBurgerMenu = () => {
    this.setState((prevState) => ({
       isBurgerMenuOpen: !prevState.isBurgerMenuOpen }));
  }

  closeBurgerMenu = () => {
    this.setState({ isBurgerMenuOpen: false });   
  };

  scrollToImage = (category) => {
    const albumDiv = document.getElementById(category);
    albumDiv.scrollIntoView({ behavior: 'smooth' });
  };

  playpause = (e) => {
    var music = document.getElementById("spotify-iframe-mobile");
    if (music.paused) {
      music.play();
      music.style.backgroundImage = 'url(/icons/sound.png)';
      music.style.backgroundSize = '35%';
    } else {
      music.pause();
      music.style.backgroundImage = 'url(/icons/mute.png)';
      music.style.backgroundSize= '45%';
    }
  }
  
  lockScreenOrientation = () => {
    if (window.screen && window.screen.lockOrientation) {
      window.screen.lockOrientation('portrait-primary');
    }
  }

  handleClickOutside = (e) => {
    if (this.state.isBurgerMenuOpen) {
      const burgerMenuToggle = document.querySelector('.burger-menu-toggle');
      const spotifyIframe = document.getElementById('spotify-iframe-mobile');
      if (
        burgerMenuToggle &&
        !burgerMenuToggle.contains(e.target) &&
        e.target !== spotifyIframe
      ) {
        this.closeBurgerMenu();
      }
    }
  };
  
  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
    this.lockScreenOrientation(); // Call the lockScreenOrientation function here

  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  render() {
    const gifContainerStyle = {
      position: "fixed",
      left: "50%",
      bottom: "35%",
      width: "100%",
      transform: "translateX(-50%)",
      textAlign: "center",
    };

    const footerStyle = {
      display: "block",
      position: "fixed",
      bottom: "10%",
      displaycameratag: "none",
    };

    const portfolioButtonStyle = {
      fontSize: 'large',
      color: '#dddd',
      position: 'fixed',
      fontWeight: 700,
      left: '50%',
      transform: 'translateX(-50%)',
      bottom: '50%',
    };

    const burgerMenuToggleStyle = {
      position: 'fixed',
      bottom: '-1px',
      background: 'black',
      opacity: '85%',
      width: '100%',
      padding: '2%',
      visibility: 'hidden',
      zIndex: 999,
    };

    const { showGallery, isBurgerMenuOpen } = this.state;

    const menuStyle = {
      position: isBurgerMenuOpen ? 'fixed' : 'absolute',
      top: isBurgerMenuOpen ? '85%' : '-100%',
      right: isBurgerMenuOpen ? '-25%' : '-100%',
      width: '55%',
      backgroundColor: 'black',
      opacity: '85%',
      zIndex: 999,
      paddingTop: '50%',
      transition: 'bottom 0.3s ease-in-out',
    };

    const menuItemStyle = {
      display: 'block',
      padding: '10px',
      textDecoration: 'none',
      color: 'rgba(221, 221, 221, 0.867)',
      fontSize: '0.8rem',
    };

    return (
      <div className="MobileTablet">
        <div id="burger-menu-toggle-id" className="burger-menu-toggle" style={burgerMenuToggleStyle} onClick={this.toggleBurgerMenu}>
          <span>&#9776;</span>
        </div>

        <div style={menuStyle} className={`burger-menu ${isBurgerMenuOpen ? 'sticky-menu' : ''}`}>
        <video
          playsInline
          id="spotify-iframe-mobile"
          onClick={this.playpause}
          src="/files/music.mp3"
          width="50"
          height="50"
          style={{ visibility: isBurgerMenuOpen ? 'visible' : 'hidden' }}
        ></video>
          {/* {menuItems.map((menuItem, index) => (
            <Link
              key={index}
              style={menuItemStyle}
              onClick={() => {
                this.closeBurgerMenu();
                this.scrollToImage(menuItem);
              }}
            >
              {menuItem}
            </Link>
          ))} */}
        </div>

        {showGallery ? null : (
          <HeroImage gifSrc="/home/homepagehero.gif" gifContainerStyle={gifContainerStyle}/>
        )}
        {showGallery ? (
          <CustomGallery />
        ) : (
          <>
            <Link onClick={() => this.enterPortfolio()}>
              <PortfolioButton portfolioButtonStyle={portfolioButtonStyle} />
            </Link>
            <Footer footerStyle={footerStyle} displaycameratag="none"/>
          </>
        )}
      </div>
    );
  }
}

export default Mobile;
