const initialSideBarCss = 
{
  landingPageBackground: 'black',
  sidebarBackground: 'transparent',
  marginleft: '50%',
  bottom: '10%',
  carroussel_opacity: 0,
  work_opacity: 0,
  middlesidebar: 'block',
  leftsidebar: 'none',
  rightsidebar: 'none',
  topnavbar: 'none',
  expanded: false
}

const leftSidebarCss =
{
    position: 'absolute',
    sidebarBackground: 'black',
    marginleft: '2%',
    bottom: '10%',
    carroussel_opacity: 1,
    middlesidebar: 'none',
    leftsidebar: 'block',
    rightsidebar: 'none',
}


const leftSideBarMobileCss = 
{
  position: 'absolute',
  sidebarBackground: 'white',
  marginleft: '2%',
  bottom: '18px',
  carroussel_opacity: 1,
  middlesidebar: 'none',
  leftsidebar: 'none',
  rightsidebar: 'none',
  topnavbar: 'block'
}

const rightSideBarCss =
{
  position: 'absolute',
  sidebarBackground: 'white',
  marginright: '2%',
  marginleft: 'calc(100% - 17% )',
  bottom: '0px',
  work_opacity: 1,
  middlesidebar: 'none',
  leftsidebar: 'none',
  rightsidebar: 'block',
}

export {initialSideBarCss, rightSideBarCss, leftSidebarCss,leftSideBarMobileCss}