import React, { Component } from 'react';
import { Button, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import { initialSideBarCss,rightSideBarCss, leftSidebarCss, leftSideBarMobileCss} from '../../constants/SidebarStyles'
import MainMenu from './MainMenu';
import Footer from '../shared/Footer';
import HeroImage from '../shared/HeroImage';
import PortfolioButton from '../shared/PortfolioButton';
import Mobile from '../mobile/Mobile';

class Index extends Component {
  constructor(props) {
    super(props);
    this.setExpanded = this.setExpanded.bind(this);
  }
  
  state = initialSideBarCss;
  baseState = this.state

  setExpanded = () => {
    this.setState({ expanded: !this.state.expanded });
  }

  enterPortfolio = () => {
    this.setState(leftSidebarCss)
  }
  handleClickleftMobile = () => {
    this.setState(leftSideBarMobileCss)
  }

  handleClickright = () => {
    this.setState(rightSideBarCss)

  }
  resetSidebar = () => {
    this.setState(this.baseState)
  }
  
  playpause = (e) => {
    var music = document.getElementById("spotify-iframe");
  
    if (music.paused) {
      music.play();
      music.style.backgroundImage = 'url(/icons/sound.png)'; // Change to mute icon
      music.style.backgroundSize = '50%';
    } else {
      music.pause();
      music.style.backgroundImage = 'url(/icons/mute.png)'; // Change back to sound icon
      music.style.backgroundSize= '60%';
    }
  }

  render() {
    
    //styles
    const gifContainerStyle = {
      position: "fixed",
      left: 0,
      bottom: "10%",
      width: "50%",
    };
    const portfolioButtonStyle={
      fontSize: 'large',
      color: '#dddd',
      textAlign: 'left',
      paddingLeft: 0,
      fontWeight:700
    }

    return (
      <div>
          <div className="sidebar"
            style={{
              position: `${this.state.position}`,
              backgroundColor: `${this.state.sidebarBackground}`,
              marginLeft: `${this.state.marginleft}`,
              bottom: `${this.state.bottom}`
            }}
          >
            <video playsInline id="spotify-iframe" onClick={this.playpause} src={"/files/music.mp3"} width="50" height="50" ></video>
            <Helmet>
              <style>{'body {background-color:' + `${this.state.landingPageBackground}` + ';background-position:center; background-size:cover} .container_work {opacity:' + `${this.state.work_opacity}` + ';}.container {opacity:' + `${this.state.carroussel_opacity}` + ';}'}</style>
            </Helmet>
              <div className="sidebarmenu" style={{ display: `${this.state.middlesidebar}` }}>
                <Nav className="flex-column">
                  <Link to="/latest" onClick={this.enterPortfolio}>
                  <PortfolioButton portfolioButtonStyle={portfolioButtonStyle} />
                  </Link>
                </Nav>
                <HeroImage gifSrc="/home/homepagehero.gif" gifContainerStyle={gifContainerStyle}/>
                <Footer />
                <div class="profile-name">
                  <span>GABRIEL PATRICIO</span>
                </div>
              </div>
              <MainMenu display={this.state.leftsidebar}>
              </MainMenu>
              <Footer />
          </div>
       
       <div className='mobileView'>
         <Mobile></Mobile>
        </div>
      </div>
    )
  }
}


export default Index;
