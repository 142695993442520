import React, {Component} from 'react';
import Index from './components/desktop/Index';
import './App.css'

class App extends Component {
  constructor(){
    super()
    this.state= {
    }
  }
  
  render() {
    return (
     
      <div className="App">
       <Index></Index>
      </div>
      
    );
  }
}
export default App;
