import React, { Component } from 'react';
import '../Carrousel.css'
import ReactImageAppear from 'react-image-appear';
import { images } from '../../constants/Portfolio';
import { updateMenus } from '../functions/Helpers';

class Gallery extends Component {
    constructor(props) {
        super(props)
    }
    scrollHorizontal = (e) => {
        var firstAlbumPic = document.querySelectorAll('.first-last');
        firstAlbumPic.forEach(element => {
            const rect = element.getBoundingClientRect();
            if (element.hasAttribute("category") && rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth))
                {
                    var id = element.getAttribute("category");
                    updateMenus(id);
                }
        });

        const left = document.getElementById('container');
        left.scrollLeft += e.deltaY
    }

  

    contextMenu = (e) => {
        e.preventDefault();
        var year = new Date().getFullYear();
        alert("This photo is Copyright ©" + year + "\nGabriel Patrício. All rights reserved");
    }

    preventDragHandler = (e) => {
        e.preventDefault();
    }
    
    showDetails = (e) => {
        if (e.target.className.includes('square')) {
            var key = e.currentTarget.getAttribute("data-value");
            document.getElementsByClassName('sidebar')[0].style.opacity = 0;
            document.getElementsByClassName('sidebar')[0].style.visibility = 'hidden';
            var overlay = document.getElementById('my-overlay');

            var img = document.getElementById('full-screen-img');
            img.src = "/gallery/" + key + "/" + e.target.id
            img.className = e.target.className
            overlay.style.opacity = 1;
            overlay.style.zIndex = 1;
            overlay.style.display = "inline";
        }
    }
    closeOverlay = (e) => {
        var overlay = document.getElementById('my-overlay');
        document.getElementsByClassName('sidebar')[0].style.opacity = 0.7;
        document.getElementsByClassName('sidebar')[0].style.visibility = 'visible';
        overlay.style.opacity = 0;
        overlay.style.zIndex = -1;
        overlay.style.display = "none";
        var img = document.getElementById('full-screen-img');
        img.src = ""
    }

    getId(className,id){
        if(className.includes("first-pic")){
            return id;
        }
    }

    getAllImages(){
        return images;
    }

    render() {
        let allimages = this.getAllImages();
        let images = []
        
        Object.keys(allimages).forEach(key => {
            let imagesArray = allimages[key];
            for (var i = imagesArray.length - 1; i >= 0; i--) {
                if(imagesArray[i][1].includes("youtube-video"))
                {
                    images.push(
                        <div className={imagesArray[i][1]} >
                        <iframe width="560" height="315" src={imagesArray[i][0]} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    )
                }
                else
                {
                    images.push(
                        <div data-value={key} onClick={this.showDetails} category={key} className={imagesArray[i][1]} id={this.getId(imagesArray[i][1],key)} >
                            <ReactImageAppear src={'gallery/'+key + '/thumbs/'+imagesArray[i][0]} showLoader={false}></ReactImageAppear>
                            {/* <ReactImageAppear src={key + "/thumbs/" + imagesArray[i][0]} showLoader={false}></ReactImageAppear> */}
                            <div className={"square " + imagesArray[i][1]} id={imagesArray[i][0]}></div>
                        </div>
                )
                }
            }
        });
       

        return (
            <div className="container" id="container" onWheel={(e) => this.scrollHorizontal(e)}>
                    <div className="content" onContextMenu={this.contextMenu} onDragStart={this.preventDragHandler}>
                        <div id="padding-left-limit">
                        </div>
                        {images}
                    </div>
                <div id="my-overlay" class="overlay-full-screen">
                    <img onContextMenu={this.contextMenu} id="full-screen-img"></img>
                    <a class="closebtn" onClick={this.closeOverlay}>&times;</a>
                </div>
            </div>
        )
    }
}
export default Gallery;
