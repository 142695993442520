// Sizes: 
//   LANDSCAPE:
//   2x2 - 2x2 square side by side
//   3x2 full size
//   Side by Side
//   3X3 - 3x(3+1)
//   2x2 - 2x(2+1)
//   1x1 - 1x(1+1)...

// Model
// "Model": [
//     [path, css classes ,width,height],
// ],
const gallery = {
    "Lost in Film .1": [
        ["0.JPG", "landscape first-last",3,2],
        ["1.JPG", "landscape",3,2],
        ["2.JPG", "landscape",3,2],
        ["3.JPG", "landscape",3,2],
        ["4.JPG", "landscape",3,2],
        ["5.JPG", "landscape",3,2],
        ["6.JPG", "landscape",3,2],
        ["7.JPG", "landscape",3,2],
        ["8.JPG", "landscape first-last first-pic",3,2],
        
    ],
    "Hamburg": [
        ["0.JPG", "landscape first-last",3,2],
        ["1.JPG", "portrait",3,2],
        ["2.JPG", "landscape",3,2],
        ["3.JPG", "landscape",3,2],
        ["4.JPG", "landscape",3,2],
        ["5.JPG", "landscape",3,2],
        ["6.JPG", "landscape",3,2],
        ["7.JPG", "landscape",3,2],
        ["8.JPG", "landscape first-last first-pic",3,2],
    ],
    "Marrakesh": [
        ["1.JPG", "landscape first-last",3,2],
        ["2.JPG", "landscape",3,2],
        ["3.JPG", "landscape",3,2],
        ["4.JPG", "landscape",3,2],
        ["5.JPG", "landscape",3,2],
        ["6.JPG", "landscape",3,2],
        ["7.JPG", "landscape",3,2],
        ["8.JPG", "landscape",3,2],
        ["9.JPG", "landscape",3,2],
        ["10.JPG", "landscape",3,2],
        ["11.JPG", "landscape",3,2],
        ["12.JPG", "landscape",3,2],
        ["13.JPG", "landscape first-last first-pic",3,2],
    ],
    "PPF": [
        ["1.JPG", "portrait first-last",5,5],
        ["2.JPG", "landscape",3,2],
        ["3.JPG", "landscape",3,2],
        ["4.JPG", "landscape",3,2],
        ["5.JPG", "landscape",3,2],
        ["6.JPG", "landscape",3,2],
        ["7.JPG", "landscape",3,2],
        ["8.JPG", "landscape",3,2],
        ["9.JPG", "landscape",3,2],
        ["10.JPG", "landscape",3,2],
        ["11.JPG", "landscape",3,2],
        ["12.JPG", "landscape",3,2],
        ["13.JPG", "landscape",3,2],
        ["14.JPG", "landscape first-last first-pic",3,2],
    ],
    "Berlin": [
        ["1.JPG", "landscape first-last",5,5],
        ["2.JPG", "landscape",3,2],
        ["3.JPG", "landscape",3,2],
        ["4.JPG", "landscape",3,2],
        ["5.JPG", "landscape",3,2],
        ["6.JPG", "landscape",3,2],
        ["7.JPG", "landscape",3,2],
        ["8.JPG", "landscape first-last first-pic",3,2],
    ],
    "Warm lights": [
        ["1.JPG", "landscape first-last",3,2],
        ["2.JPG", "landscape",3,2],
        ["3.JPG", "landscape",3,2],
        ["4.JPG", "landscape",3,2],
        ["5.JPG", "landscape first-last first-pic",3,2],
    ],
    "Is This Art": [
        ["1.JPG", "landscape first-last",3,2],
        ["2.JPG", "landscape",3,2],
        ["3.JPG", "landscape",3,2],
        ["4.JPG", "landscape first-last first-pic",3,2],
    ],
    "Sistelo": [
        ["1.JPG", "landscape first-last",3,2],
        ["2.JPG", "landscape",3,2],
        ["3.JPG", "landscape",3,2],
        ["4.JPG", "landscape",3,2],
        ["5.JPG", "landscape first-last first-pic",3,2],
    ],
    "Get Away": [
        ["1.JPG", "landscape first-last",3,2],
        ["2.JPG", "landscape",3,2],
        ["3.JPG", "landscape",3,2],
        ["4.JPG", "landscape first-last first-pic",3,2],
    ],
    "Prague": [
        ["0.JPG", "landscape first-last",3,2],
        ["1.JPG", "landscape",3,2],
        ["2.JPG", "landscape first-last first-pic",3,2],
    ],
    "Is This Happening": [
        ["1.JPG", "landscape first-last",5,5],
        ["2.JPG", "landscape",3,2],
        ["3.JPG", "landscape",3,2],
        ["4.JPG", "landscape",3,2],
        ["5.JPG", "landscape",3,2],
        ["6.JPG", "landscape",3,2],
        ["7.JPG", "landscape",3,2],
        ["8.JPG", "landscape first-last first-pic",3,2],
    ],
    "Malta": [
        ["0.JPG", "landscape first-last",5,5],
        ["1.JPG", "landscape",3,2],
        ["2.JPG", "landscape",3,2],
        ["3.JPG", "landscape",3,2],
        ["4.JPG", "landscape",3,2],
        ["5.JPG", "landscape first-last first-pic",3,2],
    ],
    "Den Haag": [
        ["1.JPG", "landscape first-last",5,5],
        ["2.JPG", "landscape",3,2],
        ["3.JPG", "landscape",3,2],
        ["4.JPG", "landscape",3,2],
        ["5.JPG", "landscape first-last first-pic",3,2],
    ],
    "Lost in Film .2": [
        ["1.JPG", "landscape first-last",5,5],
        ["2.JPG", "landscape",3,2],
        ["3.JPG", "landscape",3,2],
        ["4.JPG", "landscape first-last first-pic",3,2],
    ],
    "Dancing People Are Never Wrong": [
        ["1.JPG", "landscape first-last",5,5],
        ["2.JPG", "landscape",3,2],
        ["3.JPG", "landscape",3,2],
        ["4.JPG", "landscape",3,2],
        ["5.JPG", "landscape",3,2],
        ["6.JPG", "landscape",3,2],
        ["7.JPG", "landscape first-last first-pic",3,2],
    ],
    "Directed by David Lynch": [
        ["0.JPG", "landscape first-last",5,5],
        ["1.JPG", "landscape",3,2],
        ["2.JPG", "landscape",3,2],
        ["3.JPG", "landscape",3,2],
        ["4.JPG", "landscape",3,2],
        ["5.JPG", "landscape first-last first-pic",3,2],
    ],
    "Finisterra": [
        ["0.JPG", "landscape first-last",5,5],
        ["1.JPG", "landscape",3,2],
        ["2.JPG", "landscape",3,2],
        ["3.JPG", "landscape first-last first-pic",3,2],
    ],
    "Aveiro": [
        ["1.JPG", "landscape first-last",5,5],
        ["2.JPG", "landscape",3,2],
        ["3.JPG", "landscape",3,2],
        ["4.JPG", "landscape",3,2],
        ["5.JPG", "landscape first-last first-pic",3,2],
    ],
    "Paris": [
        ["0.JPG", "landscape first-last",5,5],
        ["1.JPG", "landscape",3,2],
        ["2.JPG", "landscape",3,2],
        ["3.JPG", "landscape",3,2],
        ["4.JPG", "landscape",3,2],
        ["5.JPG", "landscape",3,2],
        ["6.JPG", "portrait",3,2],
        ["7.JPG", "landscape first-last first-pic",3,2],
    ]
    // Lost in Film .3
    // California
    // Yosemite


}
const images = {};

const categories = Object.keys(gallery).reverse();
categories.forEach(category => {
    images[category] = gallery[category];
});

const menuItems = Object.keys(images);

export { images, menuItems };