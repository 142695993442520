import React, { Component } from 'react';
import { SocialIcon } from 'react-social-icons';

class Footer extends Component {
  render() {
    const { footerStyle, displaycameratag } = this.props;

    return (
      <div id="sidebar-footer" style={footerStyle}>
        <div id="camera-tag" style={{ display: `${displaycameratag}` }}>
          <p>
            <span>Yashica TL-Electro 35mm</span>
            <br></br>
            <span>Canon A-1 35mm </span>
          </p>
        </div>
        <div id="social-icons">
          <SocialIcon style={{ height: 40, width: 40 }} network="instagram" url="https://instagram.com/gabriel_patricioo" target='_blank' fgColor="#dddd" bgColor="black" />
          <SocialIcon style={{ height: 40, width: 40 }} network="youtube" url="https://www.youtube.com/@gabrielpatricio8495" target='_blank' fgColor="#dddd" bgColor="black" />
          <SocialIcon style={{ height: 40, width: 40 }} network="linkedin" url="https://www.linkedin.com/in/gpatricio" target='_blank' fgColor="#dddd" bgColor="black"/>
        </div>
      </div>
    );
  }
}

export default Footer;
